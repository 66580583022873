import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchEntityRelationships = async (params) => {
  let relationships = await fetchEntityRelationshipsFromLocal(params);
  if (!relationships?.length) {
    relationships = await fetchEntityRelationshipsFromDataBase(params);
  }
  return relationships;
};

export const fetchEntityRelationshipsFromLocal = async (params) => {
  let entity_id = params.entity_id || params.entityId;
  return await db.entity_relationships
    .filter(
      (rel) =>
        rel.parent_entity_id == entity_id ||
        rel.child_entity_id == entity_id
    )
    .toArray();
};

export const fetchEntityRelationshipsFromDataBase = async (params) => {
  console.log("params",params);
  let entityId = params.entity_id || params.entityId;
  let response = await postAPICall(
    "GET",
    `/entities/${entityId}/relationships`
  );
  return response?.data;
};
